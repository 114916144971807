<template>
  <div class="admin-login">
    <a-layout
      :style="{
        minHeight: '100vh',
        minWidth: '100%',
        background: '#fff',
        borderRadius: '6px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, .1)',
      }"
    >
      <a-row type="flex" justify="center">
        <a-col :md="24">
          <a-card
            :style="{
              margin: '0 auto',
              borderRadius: '15px',
            }"
            class="admin-login-card"
            width="100%"
            :bodyStyle="{ padding: '0px' }"
          >
            <ValidationObserver ref="observer">
              <a-form
                :model="form"
                :label-width="80"
                slot-scope="{ validate }"
                @submit.prevent="validate().then(handleSubmit)"
              >
                <!-- Login title & subtitle -->
                <a-form-item>
                  <a-row>
                    <a-col :span="24">
                      <h1 class="admin-login-title">
                        {{ labels.title }}
                        <span class="text-bold">{{ labels.appName }}</span>
                      </h1>
                      <h2 class="admin-login-subtitle">
                        {{ labels.subtitle }}
                      </h2>
                    </a-col>
                  </a-row>
                </a-form-item>

                <Input
                  v-model="form.email"
                  rules="required|email"
                  vid="email"
                  :label="labels.email.label"
                  :placeholder="labels.email.placeholder"
                  size="large"
                  icon="user"
                  iconStyle="color:rgba(0,0,0,.25)"
                  @keyup.enter.native="validate().then(handleSubmit)"
                  :autoFocus="true"
                />

                <Input
                  v-model="form.password"
                  type="password"
                  vid="password"
                  rules="required"
                  :label="labels.password.label"
                  :placeholder="labels.password.placeholder"
                  size="large"
                  icon="lock"
                  iconStyle="color:rgba(0,0,0,.25)"
                  @keyup.enter.native="validate().then(handleSubmit)"
                  autocomplete="current-password"
                />

                <!-- Remember me && forgot password -->
                <div class="admin-login-remember-forgot">
                  <a-checkbox v-model="form.remember">{{
                    labels.rememberme
                  }}</a-checkbox>
                  <a-button
                    type="link"
                    class="admin-login-forgot-password"
                    size="small"
                    @click="$router.push({ name: 'admin.password.reset' })"
                  >
                    <a-icon type="lock" />{{ labels.forgot }}
                  </a-button>
                </div>

                <a-form-item>
                  <a-button
                    @click="validate().then(handleSubmit)"
                    type="primary"
                    class="admin-login-button"
                    size="large"
                    block
                    :loading="submitting"
                    :disabled="submitting"
                    >{{ labels.login }}</a-button
                  >
                </a-form-item>
              </a-form>
            </ValidationObserver>
          </a-card>
        </a-col>
      </a-row>
    </a-layout>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Input from "../../components/core/VeeValidateForm/Input";
import labels from "@/utils/labels";
import bg from "@/assets/images/admin/login-bg.jpg";
import { mapActions, mapState } from "vuex";
import { adminPageTitle } from "../../utils/utils";
export default {
  name: "Login",
  components: {
    ValidationObserver,
    Input,
  },
  metaInfo: {
    title: adminPageTitle("Iniciar sesión"),
  },
  data() {
    return {
      labels: {
        ...labels.login,
        appName: labels.appName,
        unknownServerError: labels.unknownServerError,
      },
      bg,
      form: {
        email: "",
        password: "",
        remember: false,
      },
      submitting: false,
    };
  },
  methods: {
    ...mapActions("adminAuth", ["handleLogin"]),
    async handleSubmit() {
      if (this.submitting) return;
      try {
        this.submitting = true;
        let user = await this.handleLogin(this.form);
        const redirectRoute = this.redirectRoute;
        this.$router.push({ name: redirectRoute || "admin.dashboard" });
        this.$store.state.redirectRoute = null;
        this.$message.success(`${this.labels.success}, ${user.name}`);
      } catch (error) {
        if (error.isAxiosError && error.response.status === 401) {
          this.$error({
            title: this.labels.error,
            content:
              error.response?.data?.message ||
              error?.message ||
              this.labels.errorMessage,
            centered: true,
          });
        } else {
          this.$notification["error"]({
            message: `${
              error?.response?.message || error?.message || this.labels.error
            } ${error?.response?.status || 500}`,
            description:
              error?.response?.data?.message || this.labels.unknownServerError,
          });
        }
      } finally {
        this.submitting = false;
      }
    },
  },
  computed: {
    ...mapState(["redirectRoute"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variable";
.admin-login {
  font-family: "Montserrat", sans-serif;
  &-forgot-password {
    color: color(muted);
    @media screen and (max-width: $screen-mobile) {
      padding: 0;
    }
  }
  &-button {
    background-color: color(primary) !important;
    box-shadow: 0 8px 15px 0 color(primary-shadow);
    border-radius: 999px;
  }
  &-remember-forgot {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: $screen-mobile) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
  .ant-layout {
    background: url(../../assets/images/admin/login-bg.jpg) no-repeat !important;
    background-color: color(primary) !important;
    min-height: 100vh;
    min-width: 100%;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: $screen-mobile) {
      background-size: cover !important;
    }
  }
  &-card {
    width: 500px;
    padding: 20px 30px;
    background-color: color(--white);
    position: relative;
    z-index: 9;
    margin-top: 10% !important;
    // box shadow left
    box-shadow: -25px 25px 0px 0px color(primary);
    @media screen and (max-width: $screen-mobile) {
      width: 96%;
      padding: 20px;
      margin: 0 auto;
      box-shadow: none;
      margin-top: 30% !important;
    }
  }
  &-title {
    font-size: 24px;
    font-weight: 500;
    color: color(title);
    margin-bottom: 0.1rem;
    text-align: center;
    @media screen and (max-width: $screen-mobile) {
      font-size: 20px;
    }
  }
  &-subtitle {
    font-size: 16px;
    font-weight: 400;
    color: color(subtitle);
    margin-bottom: 0px;
    text-align: center;
    @media screen and (max-width: $screen-mobile) {
      font-size: 14px;
    }
  }
}
</style>
